import React from "react";
import styled from "styled-components";

import { Props } from "./VideoBlock.types";

const StyledVideo = styled.video`
  box-sizing: border-box;
  display: block;
  object-fit: cover;
  transition-property: all;
  transition-duration: 1s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0, 0.51, 0.25, 1);
  border-radius: ${(props: Props) => props.roundedBorders ? "1.5rem" : undefined};
  box-shadow: 0 1px 1px rgba(0,0,0,0.12),
              0 2px 2px rgba(0,0,0,0.12),
              0 4px 4px rgba(0,0,0,0.12),
              0 8px 8px rgba(0,0,0,0.12),
              0 16px 16px rgba(0,0,0,0.12);
`;

export const VideoBlock: React.FC<Props> = (props) => {  
  return (
    <StyledVideo
      controls
      src={props.src}
      width={props.width}
      height={props.height}
      poster={props.poster}
      muted={props.isMuted ?? true}
      autoPlay={props.autoPlay ?? true}
      playsInline={props.playsInline ?? true}
      loop={props.loop ?? true}
      roundedBorders={props.roundedBorders ?? true}
    >
    </StyledVideo>
  );
}
