import React from "react";
import styled from "styled-components";

import { Header } from "../Components/Header";
import { VideoBlock } from "../Components/VideoBlock/VideoBlock";
import idgafVideo from "../Assets/idgaf.mp4";

const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

export const Idgaf: React.FC = () => {

  return (
    <>
      <Header
        logoTitle="Vishrut"
        navItems={[
          {
            label: "Home",
            uniconIcon: "uil uil-estate",
            link: "#home"
          },
          {
            label: "About",
            uniconIcon: "uil uil-user",
            link: "#about"
          },
          {
            label: "Skills",
            uniconIcon: "uil uil-file-alt",
            link: "#skills"
          },
          {
            label: "Services",
            uniconIcon: "uil uil-briefcase-alt",
            link: "#services"
          },
          {
            label: "Portfolio",
            uniconIcon: "uil uil-scenery",
            link: "#portfolio"
          },
          {
            label: "Contact",
            uniconIcon: "uil uil-message",
            link: "#contact"
          }
        ]}
      />
      <main>
        <Container>
          <VideoBlock
            src={idgafVideo}
            width={400}
            height={600}
            isMuted={true}
            roundedBorders
            autoPlay
            loop
          />
        </Container>
      </main>
    </>
  );
}